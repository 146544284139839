define("extraapps/services/ea", ["exports", "extraapps/util/promiseObject", "extraapps/util/util", "extraapps/config/environment"], function (exports, _promiseObject, _util, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        ajax: Ember.inject.service(),
        ea: Ember.inject.service(),
        cache: Ember.inject.service(),
        session: Ember.inject.service(),
        baseURL: "https://ksahn.de/backend/rs",

        init: function init() {
            Object.defineProperty(this, 'container', {
                get: function get() {
                    return Ember.getOwner(this);
                }
            });
            Object.defineProperty(this, 'router', {
                get: function get() {
                    return this.container.lookup('router:main');
                }
            });

            if (!String.prototype.startsWith) {
                String.prototype.startsWith = function (searchString, position) {
                    position = position || 0;
                    return this.substr(position, searchString.length) === searchString;
                };
            }

            if (!String.prototype.endsWith) {
                String.prototype.endsWith = function (searchString, position) {
                    var subjectString = this.toString();
                    if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
                        position = subjectString.length;
                    }
                    position -= searchString.length;
                    var lastIndex = subjectString.indexOf(searchString, position);
                    return lastIndex !== -1 && lastIndex === position;
                };
            }

            this.baseURL = _environment.default.backend.baseURL;
        },

        ping: function ping() {
            var URL = this.getServiceURL('api/ping');
            var that = this;
            this.get('ajax').request(URL, {
                dataType: 'text'
            }).then(function (data) {
                console.log("Server successfully pinged (" + URL + "): " + data);
            }).catch(function (error) {
                console.log("Error contacting server at " + URL + ": " + error);
                alert("Keine Verbindung zum Backend. Läuft der Server?"); // TODO translations, callback
            });
        },
        getDefaultHeaders: function getDefaultHeaders() {
            return this.getDefaultsHeaders();
        },
        getDefaultsHeaders: function getDefaultsHeaders() {
            var Authentication = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJwdWJsaWMiLCJpc3MiOiIxIn0.yfaXBH2V8D-ZE0i61RjvjsELtqu5_YVdVEoEsXA7ViA';
            var Authorization = 'Basic ZHVtbXk6Nzc3';
            var session = this.get('session');
            var client = null;if (!("client_id" in session)) {
                client = 1;
            } else {
                client = session.client_id;
            }
            var aid = null;
            if (!("app_id" in session)) {
                aid = 8731;
            } else {
                aid = session.app_id;
            }
            if (aid === 0 || aid === undefined) {
                aid = 8731;
            }
            if ((0, _util.isNotEmpty)(session.session.content.authenticated.token)) {
                var today = new Date();
                var milliseconds = today.getMilliseconds();
                var authString = "dummy:" + milliseconds;
                authString = btoa(authString);
                var headers = {
                    //'Authorization': 'Basic ZHVtbXk6ZHVtbXk=',
                    //'Authorization': `Basic ${authString}`,
                    'Authentication': "Bearer " + session.session.content.authenticated.token,
                    'cid': 1,
                    'Content-Type': 'application/json',
                    'aid': aid
                };
                return headers;
            } else {
                return {
                    'cid': 1, // Wichtig da sonst unklar zu welchem Client der User gehört. Im
                    'aid': aid,
                    'Content-Type': 'application/json',
                    //'Authorization': Authorization,
                    'Authentication': Authentication
                };
            }
        },
        getDefaultHeaderFiles: function getDefaultHeaderFiles() {
            /*
            let client = 1;
            return {
                'aid': 1,
                'Authorization': 'Basic bWF4bXVzdGVy=',
                'cid': client,
            };
            */
            var headers = this.getDefaultsHeaders();
            delete headers["Content-Type"];
            //headers['Content-Type'] = undefined; // will be set later
            return headers;
        },


        // http://www.2ality.com/2012/06/continuation-passing-style.html
        /** Use like this:
         *
         ea: Ember.inject.service(),
          someUsingFunction: function() {
            let that = this;
            [...]
            this.get('ea').forEachCps(array,
                function (element, index, next) {       // visitor
                    // do something with element = array[index], then call next()
                },
                that.myDoneFunction,
                that
            );
        },
          myDoneFunction: function() {
            [...]
        }
          * @param array
         * @param visitorFunction
         * @param doneFunction
         * @param doneArg
         */
        forEachCps: function forEachCps(array, visitorFunction, doneFunction, doneArg) {
            // recursion starter
            this.forEachCpsRec(0, array, visitorFunction, doneFunction, doneArg);
        },

        forEachCpsRec: function forEachCpsRec(index, array, visitor, doneFunction, doneArg) {
            var that = this;
            if (index < array.length) {
                visitor(array[index], index, function () {
                    // next
                    that.forEachCpsRec(index + 1, array, visitor, doneFunction, doneArg);
                });
            } else {
                doneFunction(doneArg);
            }
        },

        lookup: function lookup(key) {
            return this.container.lookup(key);
        },

        getController: function getController(routeName) {
            routeName = routeName || this.currentRouteName();

            var result = this.lookup("controller:" + routeName);
            return result;
        },

        getUrl: function getUrl() {
            return this.router && this.router.get('url');
        },

        getShortUrl: function getShortUrl() {
            var url = this.getUrl();
            var prefix = this.baseURL;
            var prefixLength = prefix.length;
            var URL = url.substr(prefixLength);
            URL = URL.split('/');
            return URL;
        },

        storeForm: function storeForm(container) {
            alert("now");
            if (container === null) {
                return null;
            }

            var created = container.createdOn;
            var updated = container.updatedOn;
            created = created.split(" ");
            updated = updated.split(" ");
            if (created[1] !== undefined) {
                created = created[0] + "T" + created[1];
            }
            if (updated[1] !== undefined) {
                updated = updated[0] + "T" + updated[1];
            }
            var URL = this.getServiceURL('ea/form');
            var insertData = [];

            insertData.push(container);
            var records = {};
            records.resource = insertData;

            var json = JSON.stringify(records);
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, {
                    data: json,
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var updatedRecord = data.resource[0];
                    resolve(updatedRecord);
                }).catch(function (error) {
                    var reason = {
                        service: 'ea',
                        method: 'storeForm',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.storeForm(" + JSON.stringify(container) + ")");
        },

        getForms: function getForms() {
            var URL = this.getServiceURL('ea/form');
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, {
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var forms = data.resource;
                    resolve(forms);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.getForms()");
        },

        getForm: function getForm(formID) {
            var URL = this.getServiceURL("ea/form/" + formID);
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, {
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var form = data.resource[0];
                    resolve(form);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.getForm(" + formID + ")");
        },

        deleteForm: function deleteForm(formID) {
            var URL = this.getServiceURL("ea/form/" + formID);
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, {
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var deletedForm = data.resource[0];
                    resolve(deletedForm);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.deleteForm(" + formID + ")");
        },

        storeTemplate: function storeTemplate(template) {
            if (template === null) {
                return null;
            }
            var URL = this.getServiceURL('ea/template');
            var insertData = [];
            if (template.aid === undefined) {
                Ember.set(template, "aid", this.get("session").get("app_id"));
            }

            insertData.push(template);
            var records = {};
            records.resource = insertData;

            var json = JSON.stringify(records);
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, {
                    data: json,
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var updatedRecord = data.resource[0];
                    resolve(updatedRecord);
                }).catch(function (error) {
                    var reason = {
                        service: 'ea',
                        method: 'storeTemplate',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });

            return (0, _promiseObject.promiseObject)(promise, "ea.storeTemplate(" + JSON.stringify(template) + ")");
        },

        getTemplates: function getTemplates() {
            var URL = this.getServiceURL('ea/template');
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, {
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var templates = data.resource;
                    resolve(templates);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, "ea.getTemplates()");
        },

        getTemplate: function getTemplate(templateID) {
            var URL = this.getServiceURL("ea/template/" + templateID);
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, {
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var template = data.resource[0];
                    resolve(template);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.getTemplate(" + templateID + ")");
        },

        deleteTemplate: function deleteTemplate(templateID) {
            var URL = this.getServiceURL("ea/template/" + templateID);
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, {
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var deltedTemplate = data.resource[0];
                    resolve(deltedTemplate);
                }).catch(function (error) {
                    var reason = {
                        service: 'ea',
                        method: 'deleteTemplate',
                        args: {
                            id: templateID
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.deleteTemplate(" + templateID + ")");
        },

        getRoutes: function getRoutes() {
            var URL = this.getServiceURL('route/meta');
            var that = this;
            var cache = this.get("cache");
            var routes = null; //cache.getArray("route");
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (routes === null) {
                    that.get('ajax').request(URL, {
                        headers: that.getDefaultsHeaders()
                    }).then(function (data) {
                        var routeData = data.resource;
                        resolve(routeData);
                        //cache.insertArray("route", routeData);
                    }).catch(function (error) {
                        var reason = {
                            error: error
                        };
                        reject(reason);
                    });
                } else {
                    resolve(routes);
                }
            });
            return (0, _promiseObject.promiseArray)(promise, "ea.getRoutes");
        },

        updateRoutes: function updateRoutes(route) {
            var URL = this.getServiceURL('route');
            delete route['databases'];
            delete route['forms'];
            delete route['handlebarsTemplates'];
            delete route['relationTypes'];
            delete route['relatedDatasets'];
            delete route['withGeoData'];
            delete route['childWantsFullScreen'];
            delete route["withTemporalData"];
            route.cid = this.get("session").get("client_id");
            if (route.aid === undefined) {
                //IMPORTANT! HAS TO BE SET MANUALY FOR APPROUTE ON APP CREATION
                route.aid = this.get("session").get("app_id");
            }
            var records = {
                resource: [route]
            };
            var json = JSON.stringify(records);
            var that = this;
            var cache = this.get("cache");
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, {
                    data: json,
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var insertedRoute = data.resource[0];
                    resolve(insertedRoute);
                    cache.insertObject("route", insertedRoute);
                }).catch(function (error) {
                    var reason = {
                        service: 'ea',
                        method: 'updateRoutes',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });

            return (0, _promiseObject.promiseObject)(promise, "ea.updateRoutes()");
        },

        deleteRoute: function deleteRoute(id) {
            var URL = this.getServiceURL("route/" + id);
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                var _this = this;

                that.get('ajax').delete(URL, {
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var deletedRoute = data;
                    resolve(deletedRoute);
                    _this.get("cache").deleteObject(id, "page", "byId");
                }).catch(function (error) {
                    var reason = {
                        service: 'ea',
                        method: 'deleteRoute',
                        args: {
                            id: id
                        },
                        error: error
                    };
                    reject(reason);
                });
            });

            return (0, _promiseObject.promiseObject)(promise, "ea.deleteRoute(" + id + ")");
        },
        removeTemplateFromRoute: function removeTemplateFromRoute(templateID, pathSegment) {
            var ea = this.get("ea");
            var baseURL = ea.getBaseURL();
            var URL = baseURL + "/route/route_template/" + templateID + "?pathSegment=" + pathSegment;
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, {
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var insertedRelation = data.resource[0];
                    resolve(insertedRelation);
                }).catch(function (error) {
                    var reason = {
                        service: 'ea',
                        method: 'deleteTemplateToRoute',
                        args: {},
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.removeTemplateFromRoute(" + templateID + ", " + pathSegment + ")");
        },
        addTemplateToRoute: function addTemplateToRoute(templateID, pathSegment) {
            var URL = this.getServiceURL('route/template');
            var insertData = [];

            insertData.push({
                "pathSegment": pathSegment,
                "templateId": templateID
            });
            var records = {};
            records.resource = insertData;

            var json = JSON.stringify(records);
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, {
                    data: json,
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var insertedRelation = data.resource[0];
                    resolve(insertedRelation);
                }).catch(function (error) {
                    var reason = {
                        service: 'ea',
                        method: 'addTemplateToRoute',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.addTemplateToRoute(" + templateID + ", " + pathSegment + ")");
        },

        addFormToRoute: function addFormToRoute(formId, pathSegment) {
            var URL = this.getServiceURL('route/form');
            var insertData = [];

            insertData.push({
                "pathSegment": pathSegment,
                "formId": formId
            });
            var records = {};
            records.resource = insertData;

            var json = JSON.stringify(records);
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, {
                    data: json,
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var insertedRelation = data.resource[0];
                    resolve(insertedRelation);
                }).catch(function (error) {
                    var reason = {
                        service: 'ea',
                        method: 'addFormToRoute',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.addFormToRoute(" + formID + ", " + pathSegment + ")");
        },

        removeFormFromRoute: function removeFormFromRoute(formId, pathSegment) {
            var URL = this.baseURL;
            var insertData = [];

            insertData.push({
                "pathSegment": pathSegment,
                "formId": formId
            });
            var records = {};
            records.resource = insertData;

            var json = JSON.stringify(records);
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, {
                    data: json,
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var insertedRelation = data.resource[0];
                    resolve(insertedRelation);
                }).catch(function (error) {
                    var reason = {
                        service: 'ea',
                        method: 'removeFormFromRoute',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.addFormToRoute(" + formID + ", " + pathSegment + ")");
        },

        addDatasetToRoute: function addDatasetToRoute(datasetName, pathSegment, filter) {
            var URL = this.getServiceURL('/route/dataset');
            var insertData = [];

            insertData.push({
                "pathSegment": pathSegment,
                "databaseName": "_external",
                "datasetName": datasetName,
                "filterExpression": filter
            });
            var records = {};
            records.resource = insertData;

            var json = JSON.stringify(records);
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, {
                    data: json,
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var insertedRelation = data.resource[0];
                    resolve(insertedRelation);
                }).catch(function (error) {
                    var reason = {
                        service: 'ea',
                        method: 'storeTemplate',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.addFormToRoute(" + datasetName + ", " + pathSegment + ")");
        },

        removeDatasetFromRoute: function removeDatasetFromRoute(datasetName, pathSegment) {
            var URL = this.getServiceURL('/route/dataset');
            var insertData = [];

            insertData.push({
                "pathSegment": pathSegment,
                "datasetName": datasetName,
                "databaseName": "_external"
            });
            var records = {};
            records.resource = insertData;

            var json = JSON.stringify(records);
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, {
                    data: json,
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var insertedRelation = data.resource[0];
                    resolve(insertedRelation);
                }).catch(function (error) {
                    var reason = {
                        service: 'ea',
                        method: 'removeDatasetFromRoute',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.removeDatasetFormToRoute(" + datasetName + ", " + pathSegment + ")");
        },

        getRouteData: function getRouteData(pathSegment) {
            var cache = this.get("cache");
            var routeData = null; //cache.getObject("routeData", pathSegment, "pathSegment");
            var URL = this.getServiceURL("route/data?pathSegment=" + pathSegment);
            var that = this;
            var headers = that.getDefaultsHeaders();
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (routeData === null) {
                    that.get('ajax').request(URL, { headers: headers }).then(function (data) {
                        resolve(data.resource);
                        cache.insertObject("routeData", data.resource);
                    }).catch(function (error) {
                        alert(error);
                        var reason = {
                            error: error
                        };
                        reject(reason);
                    });
                } else {
                    resolve(routeData);
                }
            });
            return (0, _promiseObject.promiseArray)(promise, "ea.getRouteData(" + pathSegment + ")");
        },

        getRouteMeta: function getRouteMeta(pathSegment) {
            var cache = this.get("cache");
            var URL = this.getServiceURL("route/meta?pathSegment=" + pathSegment);
            var that = this;
            var routeMeta = null; //cache.getObject("routeMeta", pathSegment, "pathSegment");
            var headers = that.getDefaultsHeaders();
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (routeMeta === null) {
                    that.get('ajax').request(URL, {
                        headers: headers
                    }).then(function (data) {
                        var cleanMeta = (0, _util.cleanRouteMeta)(data);
                        resolve(cleanMeta);
                        cache.insertObject("routeMeta", data);
                    }).catch(function (error) {
                        var reason = {
                            error: error
                        };
                        reject(reason);
                    });
                } else {
                    resolve(routeMeta);
                }
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.getRouteMeta(" + pathSegment + ")");
        },

        getApps: function getApps() {
            var cache = this.get("cache");
            var URL = _environment.default.backend.baseURL + "/apps";
            var that = this;
            var apps = null; //cache.getArray("apps");
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (apps === null) {
                    that.get('ajax').request(URL, {
                        headers: that.getDefaultsHeaders()
                    }).then(function (data) {
                        resolve(data.resource);
                        cache.insertArray("apps", data.resource);
                    }).catch(function (error) {
                        var reason = {
                            error: error
                        };
                        reject(reason);
                    });
                } else {
                    resolve(apps);
                }
            });
            return (0, _promiseObject.promiseArray)(promise, "ea.getApps()");
        },
        getApp: function getApp(id) {
            var cache = this.get("cache");
            var URL = _environment.default.backend.baseURL + "/apps/" + id;
            var that = this;
            var app = cache.getObject("apps", id);
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (app === null) {
                    that.get('ajax').request(URL, {
                        headers: that.getDefaultsHeaders()
                    }).then(function (data) {
                        resolve(data);
                        cache.insertObject("apps", data);
                    }).catch(function (error) {
                        var reason = {
                            error: error
                        };
                        reject(reason);
                    });
                } else {
                    resolve(app);
                }
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.getApps()");
        },
        insertApp: function insertApp(app) {
            var cache = this.get("cache");
            var URL = _environment.default.backend.baseURL + "/apps";
            var cid = this.get("session").get("client_id");
            if (cid === undefined) {
                cid = 1;
            }
            app.cid = cid;
            var json = JSON.stringify(app);
            var that = this;
            var params = {
                data: json,
                headers: that.get("ea").getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, params).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'ea',
                        method: 'insertApp',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.insertApp(" + app + ")");
        },
        removeApp: function removeApp(id) {
            var that = this;
            var cache = this.get("cache");
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(_environment.default.backend.baseURL + "/apps/" + id, {
                    headers: that.get("ea").getDefaultsHeaders()
                }).then(function (data) {
                    resolve(data);
                    cache.removeObject("apps", id);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'deleteGroup',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "auth.removeApp(" + id + ")");
        },

        appendQueryParameters: function appendQueryParameters(url, parameters) {
            var str = [];
            for (var key in parameters) {
                if (parameters.hasOwnProperty(key)) {
                    var value = parameters[key];
                    if ((0, _util.isNotEmpty)(value)) {
                        str.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
                    }
                }
            }
            if (str.length > 0) {
                return url + "?" + str.join("&");
            }
            return url;
        },

        getServiceURL: function getServiceURL(suffix) {
            if ((0, _util.isNotEmpty)(suffix)) {
                if (suffix.startsWith('/')) {
                    return "" + this.baseURL + suffix;
                } else {
                    return this.baseURL + "/" + suffix;
                }
            }
            console.log("WARNING ea.getServicURL called with empty suffix.");
            return this.baseURL;
        },

        getBaseURL: function getBaseURL() {
            return this.baseURL;
        },

        insertValue: function insertValue(valueList) {
            var URL = this.getServiceURL("valuelist");
            var that = this;
            var json = {
                resource: valueList
            };
            json = JSON.stringify(json);

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, {
                    data: json,
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var routeMeta = data;
                    resolve(routeMeta);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.insertValueList(" + valueList + ")");
        },

        getValueList: function getValueList(listName) {

            var URL = this.getServiceURL("valuelist/" + listName);
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, {
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var valueLists = data.resource;
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.getValueLists()");
        },

        deleteValueList: function deleteValueList(listName) {

            var URL = this.getServiceURL("valuelist/" + listName);
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, {
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    resolve(data.resource);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.getValueLists()");
        },

        getValueLists: function getValueLists() {

            var URL = this.getServiceURL("valuelist");
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, {
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var valueLists = data.resource;
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.getValueLists()");
        }
    });
});