define('extraapps/themes/ember-bootstrap-v3', ['exports', 'ember-models-table/themes/ember-bootstrap-v3'], function (exports, _emberBootstrapV) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberBootstrapV.default;
    }
  });
});