define('extraapps/admin/view/controller', ['exports', 'ember-parachute'], function (exports, _emberParachute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.myQueryParams = undefined;
    var myQueryParams = exports.myQueryParams = new _emberParachute.default({
        datasets: {
            defaultValue: [],
            shouldRefresh: true,
            serialize: function serialize(value) {
                return value.toString();
            },
            deserialize: function deserialize() {
                var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

                return value.split(',');
            }
        },

        display: {
            defaultValue: null
        }
    });

    exports.default = Ember.Controller.extend(myQueryParams.Mixin, {
        actions: {
            add: function add(value) {
                var datasets = this.get("datasets");
                datasets.pushObject(value);
                this.toggleProperty("refresh");
            },
            layout: function layout(value) {
                this.set("display", value);
            },
            newRecord: function newRecord(ds) {
                this.transitionToRoute("admin.record", ds, 0);
            },
            goTo: function goTo(ds, id) {
                this.transitionToRoute("admin.record", ds, id);
            },
            setLayout: function setLayout(layout) {
                this.set("display", layout);
            }

        }
    });
});