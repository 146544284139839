define('extraapps/helpers/stringify', ['exports', 'ember-models-table/helpers/stringify'], function (exports, _stringify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _stringify.default;
    }
  });
  Object.defineProperty(exports, 'stringify', {
    enumerable: true,
    get: function () {
      return _stringify.stringify;
    }
  });
});